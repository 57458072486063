import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import AuthProvider from './authToken';
import GlobalStyle from './GlobalStyle';
import './GlobalStyle/custom.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<GlobalStyle />
		<AuthProvider>
			<App />
		</AuthProvider>
	</React.StrictMode>
);
